import state from "../state"
import api from "../api"
import useToast from "@/utils/toast"
import useErroReasonModal from "./useErroReasonModal"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErroReasonModal()

  const turnOnModal = () => {
    state.downloadExcelModal.isVisible = true
  }

  const turnOffModal = () => {
    state.downloadExcelModal.isVisible = false
  }

  const downloadExcel = () => {
    state.downloadExcelModal.isBusy = true
    return api.downloadExcel({
      ...state.filterParams,
      is_update_state: state.downloadExcelModal.isUpdateState,
    }).then(() => {
      makeToast("primary", "엑셀 이메일 전송요청에 성공했습니다")
      turnOffModal()
    }).catch(err => {
      makeToast("danger", "엑셀 이메일 전송요청에 실패했습니다")
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.downloadExcelModal.isBusy = false
    })
  }

  return {
    turnOnModal,
    turnOffModal,
    downloadExcel,
  }
}
