<template>
  <TagbyModal
    :isVisible="state.downloadExcelModal.isVisible"
    :isBusy="state.downloadExcelModal.isBusy"
    title="엑셀 다운로드"
    @ok="downloadExcel"
    @hidden="turnOffModal"
  >
    <BFormCheckbox v-model="state.downloadExcelModal.isUpdateState">
      상품 준비중으로 상태 변경
    </BFormCheckbox>
  </TagbyModal>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BFormCheckbox,
} from "bootstrap-vue"
import TagbyModal from "@/components/TagbyModal.vue"
import state from "../../state"
import useDownloadExcel from "../../hooks/useDownloadExcel"

export default defineComponent({
  components: {
    TagbyModal,
    BFormCheckbox,
  },
  setup() {
    const {
      downloadExcel,
      turnOffModal,
    } = useDownloadExcel()
    return {
      state,
      downloadExcel,
      turnOffModal,
    }
  },
})
</script>
