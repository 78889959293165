import state from "../state"
import api from "../api"
import useInitialize from "./useInitialize"
import useItems from "./useItems"

export default () => {
  const {
    initialize,
  } = useInitialize()

  const {
    searchList,
  } = useItems()

  const getDashboard = () => api.getDashboard().then(response => {
    const resData = response.data.data
    state.dashboard.orderedCount = resData.ordered_count
    state.dashboard.notDeliveryYetCount = resData.not_delivery_yet_count
    state.dashboard.deliveryCount = resData.delivery_count
    state.dashboard.cancelingCount = resData.canceling_count
  })

  const executeShortcut = () => {
    initialize()
    searchList()
  }

  return {
    getDashboard,
    executeShortcut,
  }
}
