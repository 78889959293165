<template>
  <div>
    <TagbyFilterV2
      :initialParams="state.filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    >
      <template #shortcut>
        <Shortcut
          variant="primary"
          :query="{state: 'ORDERED', pay_type: 'ACCOUNT'}"
          @click="executeShortcut"
        >
          입금확인중: {{ state.dashboard.orderedCount }}
        </Shortcut>

        <Shortcut
          variant="warning"
          :query="{state: ['PAID', 'READY']}"
          @click="executeShortcut"
        >
          배송준비중: {{ state.dashboard.notDeliveryYetCount }}
        </Shortcut>

        <Shortcut
          variant="info"
          :query="{state: 'DELIVERY'}"
          @click="executeShortcut"
        >
          배송중: {{ state.dashboard.deliveryCount }}
        </Shortcut>

        <Shortcut
          variant="danger"
          :query="{state: 'CANCELING', pay_type: 'ACCOUNT'}"
          @click="executeShortcut"
        >
          무통장취소중: {{ state.dashboard.cancelingCount }}
        </Shortcut>
      </template>
      <template #buttons>
        <BButton
          variant="outline-success"
          class="mr-1"
          @click="turnOnDonwloadExcelModal"
        >
          엑셀 이메일 전송
        </BButton>
      </template>
    </TagbyFilterV2>
    <TagbyList
      v-model="state.currentPage"
      :items="getters.items"
      :fields="tableColumns"
      :busy="state.isBusy"
      :current-page="state.currentPage"
      :total-rows="state.totalRows"
      :per-page="state.perPage"
      @page-change="changePage"
      @sort-changed="changeSort"
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'commerce-order-group-detail', params: { idx: data.item.order_group_idx} }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(order_group_no)="data">
        <TagbyConditionalLink
          :stateList="['COPY', 'TEXT']"
          :text="data.value"
        />
      </template>

      <template #cell(order_no)="data">
        <TagbyConditionalLink
          :stateList="['COPY', 'TEXT']"
          :text="data.value"
        />
      </template>

      <template #cell(myshop_url_path)="data">
        <BLink
          :href="resolveMyshopUrl(data.value)"
          target="_blank"
        >
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(buyer)="data">
        <div>
          <div>{{ data.item.buyer_name }}</div>
          <div>{{ data.item.buyer_phone }}</div>
        </div>
      </template>

      <template #cell(delivery)="data">
        <div>
          <div>{{ data.item.shipment_company }}</div>
          <div>{{ data.item.tracking_no }}</div>
        </div>
      </template>

      <template #cell(state)="data">
        {{ resolveOrderState(data.value) }}
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(amount)="data">
        {{ resolveMoney(data.value) }}
      </template>

      <template #cell(group_amount)="data">
        {{ resolveMoney(data.value) }}
      </template>
    </TagbyList>
    <Modals />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BLink,
  BButton,
  BAvatarGroup,
  BBadge,
  BAvatar,
  BSpinner,
} from "bootstrap-vue"
import useInitialize from "./hooks/useInitialize"
import useItems from "./hooks/useItems"
import useState from "./hooks/useState"
import TagbyFilterV2 from "@/components/TagbyFilterV2.vue"
import TagbyList from "@/components/TagbyList.vue"
import TagbyDatetimeColumn from "@/components/TagbyDatetimeColumn.vue"
import TagbySnsAvatarV2 from "@/components/TagbySnsAvatarV2.vue"
import TagbyConditionalLink from "@/components/TagbyConditionalLink.vue"
import state from "./state"
import getters from "./getters"
import Shortcut from "./components/Shortcut.vue"
import useDashboard from "./hooks/useDashboard"
import useResolveValue from "@/utils/resolveValue"
import useDownloadExcel from "./hooks/useDownloadExcel"
import Modals from "./components/Modals"

export default defineComponent({
  components: {
    BLink,
    BButton,
    BAvatar,
    BAvatarGroup,
    BBadge,
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
    TagbySnsAvatarV2,
    TagbyConditionalLink,
    Shortcut,
    BSpinner,
    Modals,
  },
  setup() {
    const {
      resetState,
    } = useState()
    resetState()

    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      resolveMoney,
    } = useResolveValue()

    const {
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    } = useItems()
    searchList()

    const {
      getDashboard,
      executeShortcut,
    } = useDashboard()
    getDashboard()

    const {
      turnOnModal: turnOnDonwloadExcelModal,
    } = useDownloadExcel()

    const resolveMyshopUrl = urlPath => {
      if (!urlPath) {
        return null
      }
      return `${process.env.VUE_APP_MYSTORE_URL}/${urlPath}`
    }

    const resolveOrderState = value => {
      if (value === "REGISTER") {
        return "결제이탈"
      }
      if (value === "ORDERED") {
        return "결제대기"
      }
      if (value === "PAID") {
        return "결제완료"
      }
      if (value === "READY") {
        return "배송준비"
      }
      if (value === "DELIVERY") {
        return "배송중"
      }
      if (value === "DONE") {
        return "배송완료"
      }
      if (value === "CANCELING") {
        return "취소중"
      }
      if (value === "CANCELED") {
        return "취소완료"
      }
      if (value === "TAKEBACK") {
        return "환불중"
      }
      if (value === "TAKEBACKED") {
        return "환불완료"
      }
      if (value === "EXCHANGE") {
        return "교환중"
      }
      if (value === "EXCHANGED") {
        return "교환완료"
      }
      return "데이터오류"
    }

    return {
      state,
      getters,
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
      resolveMyshopUrl,
      resolveOrderState,
      executeShortcut,
      resolveMoney,
      turnOnDonwloadExcelModal,
    }
  },
})
</script>
