import {
  computed,
} from "@vue/composition-api"
import useToast from "@/utils/toast"
import {
  replaceRouterQuery,
} from "@/utils/syncUrl"
import {
  values,
} from "@/utils/value"

import state from "../state"
import api from "../api"

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: state.currentPage,
    size: state.perPage,
    total: state.totalRows,
    ...state.filterParams,
  }))

  const categoryOptions = [
    {
      label: "주문그룹번호",
      paramName: "order_group_no",
    },
    {
      label: "주문상세번호",
      paramName: "order_no",
    },
    {
      label: "주문상태",
      paramName: "state",
      format: "checkbox",
      options: [
        { text: "결제대기", value: "ORDERED" },
        { text: "결제완료", value: "PAID" },
        { text: "배송준비중", value: "READY" },
        { text: "배송중", value: "DELIVERY" },
        { text: "배송완료", value: "DONE" },
        { text: "취소중", value: "CANCELING" },
        { text: "취소완료", value: "CANCELED" },
        { text: "환불완료", value: "TAKEBACKED" },
        { text: "교환완료", value: "EXCHANGED" },
        { text: "결제이탈", value: "REGISTER" },
      ],
    },
    {
      label: "결제수단",
      paramName: "pay_type",
      format: "checkbox",
      options: [
        { text: "무통장입금", value: "ACCOUNT" },
        { text: "카드", value: "CARD" },
        { text: "나이스페이", value: "NICEPAY_AUTH" },
        { text: "네이버페이", value: "NAVERPAY_AUTH" },
        { text: "포인트결제", value: "POINT" },
      ],
    },
    {
      label: "스토어명",
      paramName: "myshop_name",
    },
    {
      label: "상품명",
      paramName: "product_name",
    },
    {
      label: "벤더번호",
      paramName: "vendor_idx",
      format: "number",
    },
    {
      label: "벤더명",
      paramName: "vendor_name",
    },
    {
      label: "송장번호",
      paramName: "tracking_no",
    },
    {
      label: "구매자 이름",
      paramName: "buyer_name",
    },
    {
      label: "구매자 휴대번호",
      paramName: "buyer_phone",
    },
    {
      label: "수령자 이름",
      paramName: "recipient_name",
    },
    {
      label: "수령자 휴대번호",
      paramName: "recipient_phone",
    },
    {
      label: "입금자 이름",
      paramName: "depositor_name",
    },
    {
      label: "주문일시",
      paramName: "created_at",
      format: "date",
    },
  ]

  const searchList = () => {
    state.isBusy = true
    return api.searchList({
      ...searchParams.value,
      sort_by: state.sortBy,
    }).then(response => {
      const resData = response.data
      state.orderList = resData.data
      state.totalRows = resData.page_info.total_count
      state.filterParams = {
        ...state.filterParams,
        total: resData.page_info.total_count,
      }
    }).catch(() => {
      makeToast("danger", "목록을 가져오는데 실패 했습니다")
    }).finally(() => {
      state.isBusy = false
    })
  }

  const search = params => {
    state.currentPage = 1
    state.filterParams = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    searchList()
  }

  const tableColumns = [
    { key: "idx" },
    { key: "order_group_no", label: "주문그룹번호" },
    { key: "order_no", label: "주문상세번호" },
    { key: "product_name", label: "상품명" },
    { key: "myshop_url_path", label: "스토어" },
    { key: "buyer", label: "구매자" },
    { key: "amount", label: "결제금액" },
    { key: "group_amount", label: "그룹결제금액" },
    { key: "delivery", label: "배송정보" },
    { key: "state", label: "주문상태" },
    { key: "created_at", label: "주문일시" },
  ]

  const changePage = params => {
    state.currentPage = params.page
    replaceRouterQuery(searchParams.value)
    searchList()
  }

  const changeSort = ctx => {
    state.sortBy = ctx.sortDesc ? ctx.sortBy : `-${ctx.sortBy}`
    searchList()
  }

  return {
    categoryOptions,
    tableColumns,
    searchList,
    search,
    changePage,
    changeSort,
  }
}
