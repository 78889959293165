<template>
  <BBadge
    pill
    :variant="variant"
    class="mr-1"
    :to="{name: 'commerce-order-re-list', query: query}"
    @click="$emit('click', query)"
  >
    <slot />
  </BBadge>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BBadge,
} from "bootstrap-vue"

export default defineComponent({
  components: {
    BBadge,
  },
  props: {
    variant: String,
    query: Object,
  },
  setup() {

  },
})
</script>
