<template>
  <div>
    <ErrorReasonModal />
    <DownloadExcelModal />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import ErrorReasonModal from "./ErrorReasonModal.vue"
import DownloadExcelModal from "./DownloadExcelModal.vue"

export default defineComponent({
  components: {
    ErrorReasonModal,
    DownloadExcelModal,
  },
  setup() {

  },
})
</script>
